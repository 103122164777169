.input-field {
    label {
        font-weight: 400;
    }
}

.checkbox, .radio {
    margin-top: 5px;
    display: block;

    label {
        font-size: 1rem;
    }
}

.radio + .radio, .checkbox + .checkbox {
    margin-top: 20px; // overwrite bootstrap style
}

.form-group {
    padding: 1.2rem 15px 0;
    position: relative;

    > label {
        position: relative;
        left: calc(0.75rem - 15px);
    }

    label {
        color: #9e9e9e;
        font-size: 1rem;
        -webkit-transition: 0.2s ease-out;
        transition: 0.2s ease-out;
        font-weight: 400;
    }
}
