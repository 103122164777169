/* Variables */
@import "layouts/var";

@import "components/components";

/* Base styles - be care to change this */
@import "layouts/primary";
@import "layouts/flashmess";

/* Other styles - changeable */
@import "layouts/screen";
@import "layouts/layout";

.select2-container {
    width: 100% !important;
}