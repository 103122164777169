.btn-floating {
    &.btn-xs {
        height: 30px;
        width: 30px;
        line-height: 0;
        padding: 0;
        margin: 0;

        i {
            line-height: 30px;
        }

        .fa, .material-icons {
            font-size: 16px;
        }
    }

    &.btn-sm {
        height: 40px;
        width: 40px;
        line-height: 0;
        padding: 0;
        margin: 0;

        i {
            line-height: 40px;
        }

        .fa, .material-icons {
            font-size: 20px;
        }
    }
}

.btn-link:hover, .btn-link:focus {
    color: #333;
}