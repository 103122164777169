input[type="radio"] {
    position: absolute;
    left: -9999px;

    +label {
        padding-left: 30px;
        position: relative;
        line-height: 17px;
        height: 17px;
        display: inline-block;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;

        &:before, &:after {
            box-sizing: border-box;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border-radius: 16px;
        }

        &:before {
            background: #6DBEB7;
            -webkit-transform: scale(0.0);
            transform: scale(0.0);
            transition: 0.3s ease-in-out;
        }

        &:after {
            border: 2px solid #5a5a5a;
            z-index: 1;
            transition: box-shadow 0.2s ease-in-out, 0.3s ease-in-out;
        }

        &:active {
            &:after {
                box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(#6DBEB7, 0.1);
            }
        }
    }

    &:checked {
        + label {
            &:before {
                -webkit-transform: scale(0.5);
                transform: scale(0.5);
            }
            &:after {
                border-color: #6DBEB7;
            }
        }
    }
}