/* Colors */
$color-strong-AND-b       : #000000;
$color-th-AND-td          : #000000;
$color-a                  : #000000;
$color-a--hover           : #000000;

/* Font-weight */
$font-weight-very_light   : 200;
$font-weight-light        : 400;
$font-weight-normal       : 500;
$font-weight-bold         : 700;
$font-weight-very_bold    : 900;

/* Margin */
$margin-table-p           : 0 0 0 0;


/* Line-height */
$line-height-table-p      : 1.5em;


/* Text-decoration */
$text-decoration-a        : none;
$text-decoration-a--hover : underline;


/* Border */
$border-none              : none;



/* Padding */
$tr-th-AND-td             : 5px;