.flashmess__container {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 65px;
  width: 250px;
  display: block;
  background-color: #ffffff;
  z-index: 2;
  overflow-x: hidden;

  .curent {
    position: relative;
    display: block;
  }
  .sublime {
    display: flex;
    .text{
      display: inline-block;
      padding: 0px 5px 0px 5px;
    }
  }
  .hideElem{
    -webkit-animation: hideEff 1s;
    -webkit-animation-fill-mode: forwards;
  }
  @-webkit-keyframes hideEff {
    0% { -webkit-transform: scale(1); opacity: 1; }
    100% { -webkit-transform: scale(0.1); opacity: 0; display:none; -webkit-animation-play-state: paused;}
  }
  .slideElem{
    -webkit-animation: slideEff 1s;
    -webkit-animation-fill-mode: forwards;
  }
  @-webkit-keyframes slideEff {
    0% { -webkit-transform: scale(1); opacity: 1; }
    100% { -webkit-transform: translateX(250px); opacity: 0; display:none; -webkit-animation-play-state: paused;}
  }
}