h4 {
    font-size: 1.8em;
    margin: 5px 0px 5px 0px;
}

.grid {
    .table {
        margin-bottom: 5px;
    }
    .sortable {
        color: #333333;
        transition: ease-in-out 0.3s;

        &::before {
            content: '';
            display: inline-block;
            width: 23px;
            height: 19px;
            background: url(../images/sortable.svg) no-repeat center;
            background-size: 19px;
            vertical-align: bottom;
        }

        &:hover {
            color: #00417b;
            transition: ease-in-out 0.3s;
        }
    }

    .asc {
        color: #333333;
        transition: ease-in-out 0.3s;

        &::before {
            content: '';
            display: inline-block;
            width: 23px;
            height: 19px;
            background: url(../images/icon_up.svg) no-repeat center;
            background-size: 19px;
            vertical-align: bottom;
        }

        &:hover {
            color: #00417b;
            transition: ease-in-out 0.3s;
        }
    }

    .desc {
        color: #333333;
        transition: ease-in-out 0.3s;

        &::before {
            content: '';
            display: inline-block;
            width: 23px;
            height: 19px;
            background: url(../images/icon_down.svg) no-repeat center;
            background-size: 19px;
            vertical-align: bottom;
        }

        &:hover {
            color: #00417b;
            transition: ease-in-out 0.3s;
        }
    }
}

.table {
    .changed {
        background-color: #ffff8d !important;
    }

    .closed {
        background-color: #00e676 !important;
    }

    .changed.closed {
        background-color: #c6ff00 !important;
    }
}

.usage {
    width: 150px;
    padding: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    border: 1px solid #dfdfdf;
    text-align: center;
    display: none;

    @media screen and (min-width: 1200px) {
        display: block;
    }

    h4 {
        font-size: 20px;
        margin: 20px 0 0;
    }

    span {
        font-size: 11px;
    }
}

.non-standard {
    background: rgba(255, 0, 0, 0.4);

    &.active, &.active:hover {
        background: rgba(255, 0, 0, 0.8);
    }
}

.client-red {
    border-left: 5px solid #F44336;
}

.client-orange {
    border-left: 5px solid #ff9800;
}

.client-blue {
    border-left: 5px solid #2196F3;
}

.client-light-blue {
    border-left: 5px solid #7E7FBB;
}