$off-slider-color: #939393;
$off-dot-color: #FAFAFA;
$on-slider-color: #6DBEB7;
$on-dot-color: #009688;
$disabled-slider-color: #D5D5D5;
$disabled-dot-color: #BDBDBD;

.switch {
    user-select: none;
    margin-bottom: 10px;

    label {
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
    }

    input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        left: -9999px;

        &:checked {
            + .lever {
                background: $on-slider-color;

                &:after {
                    left: 17px;
                    background: $on-dot-color;
                }
            }
        }

        &:not(:disabled) {
            + .lever {
                &:active {
                    &:after {
                        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($on-dot-color, 0.1);
                    }
                }
            }
        }

        &:disabled {
            + .lever {
                cursor: auto;
                background: $disabled-slider-color;

                &:after {
                    background: $disabled-dot-color;
                }
            }
        }
    }

    .lever {
        display: inline-block;
        position: relative;
        width: 36px;
        height: 14px;
        background-color: $off-slider-color;
        border-radius: 16px;
        margin: 0 8px;
        transition: 0.3s ease-in-out;
        vertical-align: middle;

        &:after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: $off-dot-color;
            border-radius: 50%;
            box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
            position: absolute;
            left: -1px;
            top: -3px;
            transition: box-shadow 0.2s ease-in-out, 0.3s ease-in-out;
        }
    }
}