input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    + label {
        padding-left: 30px;
        position: relative;
        line-height: 20px;
        height: 20px;
        display: inline-block;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;

        &:before, &:after {
            box-sizing: border-box;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 6px;
            top: 10px;
            width: 0;
            height: 0;
            z-index: 0;
            border-radius: 1px;
            margin-top: 2px;
            transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
            border: 3px solid transparent;
            -webkit-transform: rotateZ(37deg);
            transform: rotateZ(37deg);
            -webkit-transform-origin: 20% 40%;
            transform-origin: 100% 100%;
        }

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 2px;
            border: 2px solid #5a5a5a;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.2s ease-in-out;
        }
    }

    &:checked {
        +label {
            &:before {
                top: 0;
                left: 1px;
                width: 8px;
                height: 13px;
                border-top: 2px solid transparent;
                border-left: 2px solid transparent;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                z-index: 2;
            }

            &:after {
                background: #6DBEB7;
                border-color: #6DBEB7;
            }
        }
    }

    &[readonly="true"] {
        +label {
            &:after {
                background-color: rgba(0,0,0,0.26);
                border: 0;
            }
        }
    }
}