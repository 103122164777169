/* Charset - default UTF-8 */
@charset "UTF-8";

body{
    padding-bottom: 50px;
}

/* Styles code */
h1 {
    font-weight: $font-weight-very_bold;
}

h2 {
    font-weight: $font-weight-bold;
}

h3 {
    font-weight: $font-weight-normal;
}

h4 {
    font-weight: $font-weight-light;
}

h5 {
    font-weight: $font-weight-very_light;
}

strong, b {
    color: $color-strong-AND-b;
    font-weight: $font-weight-bold;
}

table {
    p {
        line-height: $line-height-table-p;
        margin: $margin-table-p;
    }
}

a {
    color: $color-a;
    text-decoration: $text-decoration-a;
    font-weight: $font-weight-normal;

    &:hover, &:focus {
        color: $color-a--hover;
        text-decoration: $text-decoration-a--hover;
    }

    img {
        border: $border-none;
    }

}

table {
    &.vert-center {
        tbody, thead {
            tr {
                th, td {
                    vertical-align: middle;
                }
            }
        }
    }

    thead {
        tr {
            th, td {
                font-weight: 500;
            }
        }
    }

    tr {
        th, td {
            padding: $tr-th-AND-td;
            color: $color-th-AND-td;
        }
    }
}

.brand-img{
    height: 44px;
    background-color: #fff;
    padding: 5px;
    margin-top: -7px;
}